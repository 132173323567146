import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/searchbarresult.css";
import { fetchFinancials } from "../services/api";
import financialStore from "../stores/financialStore";
import { observer } from "mobx-react";

const highlightText = (text, highlight) => {
  if (!highlight.trim()) {
    return text;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);
  return parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="font-bold">
        {part}
      </span>
    ) : (
      part
    )
  );
};

const SearchBarResults = observer(
  ({ results = [], query, searchBarOpened }) => {
    const navigate = useNavigate();

    const handleClick = async (cik) => {
      financialStore.updateFinancialData(null);
      financialStore.toggleIsLoading();
      navigate(`/${cik}/financials`); // Updated URL structure
      const data = await fetchFinancials(cik);
      financialStore.updateFinancialData(data);
      financialStore.toggleIsLoading();
    };

    return (
      query &&
      searchBarOpened && (
        <div
          className="absolute top-[125%] rounded-2xl bg-white-primary shadow-subtle-soft z-20 max-h-[400%] text-start overflow-y-auto"
          style={{ width: "-webkit-fill-available" }}
        >
          <small className="px-4 py-1 text-black-lgTint">
            {results?.length} search results found
          </small>
          {results?.length ? (
            results.map((item) => (
              <p
                className="px-4 py-1 hover:bg-lavender-primary hover:text-white-primary font-primary font-medium transition-colors duration-300 cursor-pointer"
                key={item.ticker}
                onClick={() => handleClick(item.cik)}
              >
                {highlightText(item.ticker, query)},{" "}
                {highlightText(item.name, query)}
              </p>
            ))
          ) : (
            <p className="px-4 py-1 font-primary font-medium text-black-mdTint">
              No results found
            </p>
          )}
        </div>
      )
    );
  }
);

export default SearchBarResults;
