import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import HeaderNew from "./HeaderNew";
import SideBar from "./SideBar";
import { FaRegBell, FaRegClock } from "react-icons/fa";
import { IoChatboxOutline, IoFilter } from "react-icons/io5";
import { observer } from "mobx-react";
import navigationBarStore from "../stores/navigationBarStore";

const ActivityLayout = observer(() => {
  const [collapsed, setCollapsed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 896);
  const navRef = useRef();

  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (navRef.current && !navRef.current.contains(e.target)) {
        navigationBarStore.setIsOpen(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 896);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <nav
        ref={navRef}
        className={`fixed h-[100dvh] top-0 z-40 flex transition-all duration-300 medium-large:left-0 ${
          !navigationBarStore.isOpen ? "-left-full" : "left-0"
        }`}
      >
        {/* Conditionally render HeaderNew based on isHeaderVisible */}
        {(!isSmallScreen || !collapsed) && <HeaderNew active="activity" />}
        <SideBar
          navigationLinks={[
            {
              label: "Notifications",
              icon: IoChatboxOutline,
              subLinks: [],
              exact: true,
              link: "/notifications",
            },
            {
              label: "Recently Viewed",
              link: "/recent",
              icon: FaRegClock,
              subLinks: [],
              exact: false,
            },
            {
              label: "My Screeners",
              link: "/screeners",
              icon: IoFilter,
              subLinks: [],
              exact: true,
            },
            {
              label: "My Alerts",
              link: "/alerts",
              icon: FaRegBell,
              subLinks: [],
              exact: true,
            },
          ]}
          collapsed={collapsed} // Pass collapsed state
          setCollapsed={setCollapsed} // Pass setCollapsed function
        />
      </nav>
      <div className="medium-large:ml-[320px] p-6 min-h-[100dvh]">
        <Outlet context={[navigationBarStore]} />
      </div>
    </>
  );
});

export default ActivityLayout;
