// ToggleButton.js
import React, { useState } from "react";
import "../styles/toggleButton.css"; // You can customize styles here

const ToggleButton = ({ onToggle }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled);
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={isToggled} onChange={handleToggle} />
      <span className="slider"></span>
    </label>
  );
};

export default ToggleButton;
