import React, { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { fetchData } from "../services/api";
import SearchBarResults from "./SearchBarResults";
import useDebounce from "./useDebounce"; // Import the debounce hook
import "../styles/searchbar.css";
import LoadingBar from "./LoadingBar";
import { observer } from "mobx-react";
import navigationBarStore from "../stores/navigationBarStore";

const SearchBar = observer(() => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const location = useLocation();
  const debouncedQuery = useDebounce(query, 300); // Use the debounce hook
  const [isLoading, setIsLoading] = useState(false);
  const [searchBarOpened, setSearchBarOpened] = useState(false);
  const [innerWidthHeight, setInnerWidthHeight] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const searchBarRef = useRef();

  useEffect(() => {
    console.log(debouncedQuery);
    if (debouncedQuery) {
      fetchData(debouncedQuery).then((data) => {
        setResults(data);
        setIsLoading(false);
      });
    } else {
      setResults([]);
      setIsLoading(false);
    }
  }, [debouncedQuery]);

  // Clear search bar and results when navigating to a new page
  useEffect(() => {
    setQuery("");
    setResults([]);
    setSearchBarOpened(false);
  }, [location]);

  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
        setSearchBarOpened(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidthHeight([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return (
    <div
      className="relative"
      ref={searchBarRef}
      // onMouseOver={() => !searchBarOpened && setSearchBarOpened(true)}
    >
      <div
        className="search-bar"
        style={
          innerWidthHeight[0] < 896 &&
          innerWidthHeight[0] > innerWidthHeight[1] &&
          navigationBarStore.isOpen
            ? {
                width: `calc(${innerWidthHeight[0]}px - 348px)`,
                marginLeft: "auto",
              }
            : {}
        }
      >
        <input
          className="search-input medium-large:w-72 w-full"
          placeholder="Search for Ticker"
          value={query}
          onChange={(e) => {
            setIsLoading(true);
            setQuery(e.target.value);
          }}
          onClick={() => !searchBarOpened && setSearchBarOpened(true)}
        />
        <div id="search-icon-container">
          <FaSearch id="search-icon" />
        </div>
      </div>
      {!isLoading ? (
        <SearchBarResults
          results={results}
          query={query}
          searchBarOpened={searchBarOpened}
          isLoading={isLoading}
        />
      ) : (
        <div
          className="absolute top-[125%]"
          style={{ width: "-webkit-fill-available" }}
        >
          <LoadingBar />
        </div>
      )}
    </div>
  );
});

export default SearchBar;
