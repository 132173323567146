import { observer } from "mobx-react";
import React from "react";

const SavedHighlightsTable = observer(() => {
  return (
    <>
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <div className="flex items-center">
          <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
            Saved highlights
          </h3>
        </div>
        {/* Filter buttons would go here */}
      </div>
    </>
  );
});

export default SavedHighlightsTable;
