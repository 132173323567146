import React from "react";
import "../styles/headerNew.css";
import UnleveredLogo from "../images/Unlevered-Logo-1.png"; // Adjust the path as necessary
import { useNavigate, useLocation } from "react-router-dom";
import userInfoStore from "../stores/userInfoStore";
import PlaceholderProfile from "../images/placeholderprofile.png";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import { HiOutlineDocument } from "react-icons/hi";
import financialStore from "../stores/financialStore";
import { observer } from "mobx-react";

const HeaderNew = observer(({ active = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="bg-white-xsShade drop-shadow-navigation-bar w-[85px] p-0 h-full relative z-30">
      <span className="absolute top-0 left-0 pt-8 w-full">
        <span
          onClick={() => {
            financialStore.updateFinancialData(null);
            navigate("/");
          }}
          className="cursor-pointer"
        >
          <img
            src={UnleveredLogo}
            alt="Unlevered Logo"
            width={60}
            height={60}
            className="m-auto mb-[6px]"
          />
        </span>
        <span
          className={`flex flex-col items-center cursor-pointer transition-colors duration-300 mb-6 m-auto rounded-3x mt-3`}
          onClick={() => {
            if (!location.pathname.includes("/documents")) {
              financialStore.updateFinancialData(null);
              navigate("/financials");
            }
          }}
        >
          <HiOutlineDocument
            className={`text-[42px] text-black-xsTint p-2 rounded-2xl ${
              active === "filings"
                ? "bg-lavender-smTint"
                : "hover:bg-white-smShade transition-all duration-300"
            }`}
          />
          <p className="text-[14px] text-black-xsTint">Filings</p>
        </span>
        <span
          className={`flex flex-col items-center cursor-pointer transition-colors duration-300 mb-6 m-auto rounded-3xl`}
          onClick={() => navigate("/notifications")}
        >
          <HiOutlineBars3BottomLeft
            className={`text-[42px] text-black-xsTint p-2 rounded-2xl ${
              active === "activity"
                ? "bg-lavender-smTint"
                : "hover:bg-white-smShade transition-all duration-300"
            }`}
          />
          <p className="text-[14px] text-black-xsTint">Activity</p>
        </span>
      </span>
      {userInfoStore.userInfo && (
        <span className="absolute bottom-0 left-0 pb-8 w-full">
          <span
            onClick={() => navigate("/settings")}
            className="cursor-pointer"
          >
            <img
              src={userInfoStore.userInfo.picture}
              onError={(e) => (e.target.src = PlaceholderProfile)}
              alt={userInfoStore.userInfo.name}
              width={42}
              height={42}
              className={`m-auto rounded-2xl ${
                active === "user" ? " p-2 bg-lavender-smTint" : ""
              }`}
            />
          </span>
        </span>
      )}
    </div>
  );
});

export default HeaderNew;
