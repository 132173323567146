import React, { useEffect, useRef } from "react";
import { IoMdSettings } from "react-icons/io";
import { Outlet } from "react-router-dom";
import HeaderNew from "./HeaderNew";
import SideBar from "./SideBar";
import navigationBarStore from "../stores/navigationBarStore";
import { observer } from "mobx-react";

const ProfileLayout = observer(() => {
  const navRef = useRef();
  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (navRef.current && !navRef.current.contains(e.target)) {
        navigationBarStore.setIsOpen(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <nav
        ref={navRef}
        className={`fixed h-[100dvh] top-0 z-40 flex transition-all duration-300 medium-large:left-0 ${
          !navigationBarStore.isOpen ? "-left-full" : "left-0"
        }`}
      >
        <HeaderNew active="user" />
        <SideBar
          navigationLinks={[
            {
              label: "Settings",
              link: `/settings`,
              icon: IoMdSettings,
              subLinks: [],
              exact: false,
            },
            // {
            //   label: "Payment Plans",
            //   link: `/pricing`,
            //   icon: BsCreditCard2FrontFill,
            //   subLinks: [],
            //   exact: false,
            // },
          ]}
        />
      </nav>
      <div className="medium-large:ml-[320px] p-6 min-h-[100dvh]  medium-large:pt-[109px]">
        <Outlet context={[navigationBarStore]} />
      </div>
    </>
  );
});
export default ProfileLayout;
