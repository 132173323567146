import React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { formatNumberWithPrefix } from "../constants";

export default function InsiderTable({
  titles,
  insiderData,
  handleRedirect,
  expandedRows,
  toggleRow,
  isToggled, // Add isToggled prop to control the blur effect
}) {
  return (
    <div className="w-full h-full overflow-auto">
      {!insiderData?.length ? (
        <div className="loading-page-container">
          <h3 className="text-black-mdTint">No data found</h3>
        </div>
      ) : (
        <table>
          <thead>
            {titles && (
              <tr>
                {titles.map((title, index) => (
                  <th key={index} className="px-4 whitespace-nowrap">
                    {title}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {insiderData.map((tableRowData, i) => (
              <React.Fragment key={i}>
                <tr
                  className="hover:bg-lavender-smTint"
                  onClick={() =>
                    handleRedirect(
                      tableRowData.accessionNo,
                      tableRowData.formType
                    )
                  }
                >
                  {/* Name - no blur */}
                  <td className="whitespace-nowrap py-3 px-4 cursor-pointer">
                    {tableRowData?.name || "-"}
                  </td>

                  {/* Transaction Type - no blur */}
                  <td className="whitespace-nowrap py-3 px-4 cursor-pointer">
                    <span
                      className={`table-pill ${tableRowData.totalTransaction?.transactionType
                        .replace(/\s+/g, "-")
                        .replace(/[^a-zA-Z0-9-]/g, "")}`}
                    >
                      {tableRowData?.totalTransaction?.transactionType || "-"}
                    </span>
                  </td>

                  {/* Price Per Share - conditional blur */}
                  <td className="whitespace-nowrap py-3 px-4 cursor-pointer">
                    <span
                      style={{
                        filter: isToggled ? "blur(5px)" : "none",
                        display: "block",
                      }}
                    >
                      {tableRowData?.totalTransaction?.price_per_share
                        ? formatNumberWithPrefix(
                            tableRowData?.totalTransaction?.price_per_share,
                            "$"
                          )
                        : "-"}
                    </span>
                  </td>

                  {/* Shares - conditional blur */}
                  <td className="whitespace-nowrap py-3 px-4 cursor-pointer">
                    <span
                      style={{
                        filter: isToggled ? "blur(5px)" : "none",
                        display: "block",
                      }}
                    >
                      {tableRowData?.totalTransaction?.shares || "-"}
                    </span>
                  </td>

                  {/* Value - conditional blur */}
                  <td className="whitespace-nowrap py-3 px-4 cursor-pointer">
                    <span
                      style={{
                        filter: isToggled ? "blur(5px)" : "none",
                        display: "block",
                      }}
                    >
                      {tableRowData?.totalTransaction?.value
                        ? formatNumberWithPrefix(
                            tableRowData?.totalTransaction?.value,
                            "$"
                          )
                        : "-"}
                    </span>
                  </td>

                  {/* Date - conditional blur */}
                  <td className="whitespace-nowrap py-3 px-4 cursor-pointer">
                    <span
                      style={{
                        filter: isToggled ? "blur(5px)" : "none",
                        display: "block",
                      }}
                    >
                      {tableRowData?.period_of_report || "-"}
                    </span>
                  </td>

                  {/* 10b5-1 - conditional blur */}
                  <td className="whitespace-nowrap py-3 px-4 cursor-pointer">
                    <span
                      style={{
                        filter: isToggled ? "blur(5px)" : "none",
                        display: "block",
                      }}
                    >
                      <span
                        className={`table-pill ${
                          tableRowData["is10b5-1"]
                            ? "bg-[#d4edda]"
                            : "bg-[#f8d7da]"
                        }`}
                      >
                        {tableRowData["is10b5-1"] ? "True" : "False"}
                      </span>
                    </span>
                  </td>

                  {tableRowData?.transactions?.length > 1 ? (
                    <td
                      className="whitespace-nowrap py-3 px-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRow(i);
                      }}
                    >
                      {expandedRows.includes(i) ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>

                {tableRowData?.transactions?.length > 1 &&
                  expandedRows.includes(i) &&
                  tableRowData?.transactions.map((transaction, j) => (
                    <tr className="hover:bg-lavender-smTint" key={j}>
                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade">
                        -
                      </td>

                      {/* Transaction Type - no blur */}
                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade">
                        <span
                          className={`table-pill ${transaction?.transactionType
                            .replace(/\s+/g, "-")
                            .replace(/[^a-zA-Z0-9-]/g, "")}`}
                        >
                          {transaction?.transactionType || "-"}
                        </span>
                      </td>

                      {/* Price Per Share - conditional blur */}
                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade">
                        <span
                          style={{
                            filter: isToggled ? "blur(5px)" : "none",
                            display: "block",
                          }}
                        >
                          {transaction?.price_per_share
                            ? formatNumberWithPrefix(
                                transaction.price_per_share,
                                "$"
                              )
                            : "-"}
                        </span>
                      </td>

                      {/* Shares - conditional blur */}
                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade">
                        <span
                          style={{
                            filter: isToggled ? "blur(5px)" : "none",
                            display: "block",
                          }}
                        >
                          {transaction?.shares || "-"}
                        </span>
                      </td>

                      {/* Value - conditional blur */}
                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade">
                        <span
                          style={{
                            filter: isToggled ? "blur(5px)" : "none",
                            display: "block",
                          }}
                        >
                          {transaction?.value
                            ? formatNumberWithPrefix(transaction.value, "$")
                            : "-"}
                        </span>
                      </td>

                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade">
                        -
                      </td>

                      {/* 10b5-1 - conditional blur */}
                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade">
                        <span
                          style={{
                            filter: isToggled ? "blur(5px)" : "none",
                            display: "block",
                          }}
                        >
                          <span className={`table-pill bg-[#f8d7da]`}>
                            False
                          </span>
                        </span>
                      </td>

                      <td className="whitespace-nowrap py-3 px-4 bg-white-smShade"></td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
