import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoClose } from "react-icons/io5";

export default function FilterButton({
  name,
  values,
  value,
  clearValue,
  handleFilterChange,
  currentlyOpenedFilter,
  setCurrentlyOpenedFilter,
  disabled,
  showPrompt,
  setShowPrompt, // Check if this prop is passed
}) {
  const isActive = currentlyOpenedFilter === name;

  return (
    <div
      className={`${
        isActive
          ? "bg-transparent shadow-inner-purple-outline text-lavender-primary "
          : "bg-lavender-primary text-white-primary"
      } rounded-2xl sm:px-[16px] sm:py-[10px] px-[12px] py-[10px] flex items-center justify-center gap-2 relative `}
    >
      {value && (
        <button
          className="text-salmon sm:text-sm text-[8px]"
          onClick={() => {
            clearValue();
          }}
        >
          <IoClose />
        </button>
      )}
      <button
        className={`bg-transparent font-semibold tracking-wider whitespace-nowrap flex items-center gap-1 font-primary sm:text-sm text-[8px] ${
          !value && "uppercase"
        } ${disabled && "opacity-50 cursor-not-allowed"} ${
          isActive ? "text-black" : ""
        }`}
        onClick={() => {
          if (disabled) {
            // Only call setShowPrompt if it exists
            if (setShowPrompt) {
              setShowPrompt(true);
              setTimeout(() => setShowPrompt(false), 2000);
            }
            return;
          }

          // Toggle dropdown: open if closed, close if open
          if (isActive) {
            setCurrentlyOpenedFilter(""); // Close the dropdown
          } else {
            setCurrentlyOpenedFilter(name); // Open the dropdown
          }
        }}
      >
        {value ? value : name}
        {isActive ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </button>
      {showPrompt && disabled && (
        <div className="absolute top-12 left-0 right-0 bg-salmon shadow-subtle-soft text-white text-center p-2 rounded-2xl z-20 font-primary font-medium">
          Select a year first
        </div>
      )}
      <section
        className={`absolute w-full max-h-[150px] overflow-hidden bg-white-primary shadow-subtle-soft rounded-2xl left-0 top-12 flex-col z-10 ${
          isActive ? "flex" : "hidden"
        }`}
      >
        <span className="overflow-y-auto">
          {values.map((val, index) => (
            <button
              key={index}
              className="text-black-primary w-full text-start sm:px-4 px-2 py-0.5 hover:bg-lavender-smTint hover:text-black-primary font-medium transition-all whitespace-nowrap font-primary sm:text-base text-sm"
              onClick={() => {
                setCurrentlyOpenedFilter(""); // Close dropdown after selecting a value
                handleFilterChange({ name, value: val });
              }}
            >
              {val}
            </button>
          ))}
        </span>
      </section>
    </div>
  );
}
