import React, { useEffect, useRef, useState } from "react";
import ownershipStore from "../stores/ownershipStore";
import "../styles/ownership.css";
import FilterButton from "./FilterButton";
import LoadingSpinner from "./LoadingSpinner";
import Table from "./Table";
import ToggleButton from "./ToggleButton"; // Import ToggleButton component
import { getHexCode } from "../constants/filingHexcodes";
import { observer } from "mobx-react";

const OwnershipCard = observer(({ cik, financialData }) => {
  const dropdownsRef = useRef(null);
  const [showPrompt, setShowPrompt] = useState(false); // Add state for showing prompt
  const [isToggled, setIsToggled] = useState(false); // State to control blur effect

  // Handle CIK changes
  useEffect(() => {
    if (ownershipStore.cik !== cik) {
      ownershipStore.setCik(cik);
    }

    const handleClickOutsideDropdown = (e) => {
      if (dropdownsRef.current && !dropdownsRef.current.contains(e.target)) {
        ownershipStore.setCurrentlyOpenedFilter(""); // Close filter dropdown on outside click
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [cik]);

  const handleFilterChange = async ({ name, value }) => {
    await ownershipStore.handleFilterChange(name, value);
  };

  const loadMore = async () => {
    await ownershipStore.loadMore();
  };

  const years = Array.from(
    { length: 2025 - financialData?.start_year },
    (_, i) => financialData?.start_year + i
  );
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  ).slice(
    ownershipStore.filter.year === financialData?.start_year
      ? financialData?.start_month - 1
      : 0,
    ownershipStore.filter.year === new Date().getFullYear()
      ? new Date().getMonth() + 1
      : 12
  );

  const toggleCurrentlyOpenedFilter = (filterName) => {
    if (ownershipStore.currentlyOpenedFilter === filterName) {
      ownershipStore.setCurrentlyOpenedFilter(""); // Close the dropdown if it's already open
    } else {
      ownershipStore.setCurrentlyOpenedFilter(filterName); // Open the dropdown
    }
  };

  const handleToggle = (toggleState) => {
    setIsToggled(toggleState); // Update the toggle state to control blur
  };

  return (
    <>
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <div className="flex items-center">
          <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
            Ownership
          </h3>
          {/* <ToggleButton onToggle={handleToggle} /> */}
          {/* Add Toggle Button */}
        </div>

        <div
          className="flex items-start md:gap-4 gap-1.5 flex-wrap"
          ref={dropdownsRef}
        >
          <FilterButton
            name="year"
            values={years.reverse()}
            value={ownershipStore.filter.year}
            clearValue={async (e) =>
              handleFilterChange({ name: "year", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={ownershipStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={() => toggleCurrentlyOpenedFilter("year")}
          />
          <FilterButton
            name="month"
            values={months.reverse()}
            value={ownershipStore.filter.month}
            clearValue={async (e) =>
              handleFilterChange({ name: "month", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={ownershipStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={() =>
              toggleCurrentlyOpenedFilter("month")
            }
            disabled={!ownershipStore.filter.year} // Disable if no year selected
            showPrompt={showPrompt} // Pass showPrompt
            setShowPrompt={setShowPrompt} // Pass setShowPrompt
          />
        </div>
      </div>

      {ownershipStore.loading ? (
        <div className="loading-page-container">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Table
            isToggled={isToggled} // Pass isToggled to Table component
            data={ownershipStore.ownershipData.map((ownershipItem) => ({
              ...ownershipItem,
              url: `/${cik}/documents/${ownershipItem.accessionNo}?filing=${ownershipItem.form_type}`,
              pillCN: getHexCode(ownershipItem.form_type) || "#ebf38c",
            }))}
            references={[
              {
                reference: "form_type",
                pill: true,
                condition: "144",
                pillConditionTrueCN: "table-pill bg-[#88CFFB] text-center",
                pillConditionFalseCN: "table-pill bg-[#BFE4FB] text-center",
              },
              { reference: "title" }, // Middle column to blur
              { reference: "filingDate", additionalCN: "text-right" },
            ]}
          />

          {ownershipStore.hasMoreData &&
            ownershipStore.ownershipData.length < ownershipStore.total && (
              <button
                className="ownership-load-more cursor-pointer hover:bg-lavender-smTint transition-all disabled:cursor-not-allowed"
                onClick={loadMore}
                disabled={ownershipStore.paginating}
              >
                <span
                  className={`ownership-load-more-btn ${
                    ownershipStore.paginating ? "loading" : ""
                  }`}
                >
                  {ownershipStore.paginating ? (
                    <LoadingSpinner size="w-4 h-4" className="border-2" />
                  ) : (
                    "+ Load More"
                  )}
                </span>
              </button>
            )}
        </>
      )}
    </>
  );
});

export default OwnershipCard;
