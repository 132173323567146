import React, { useEffect, useRef, useState } from "react";
import financialsStore from "../stores/financialsStore";
import "../styles/financials.css";
import FilterButton from "./FilterButton";
import LoadingSpinner from "./LoadingSpinner";
import Table from "./Table";
import { getHexCode } from "../constants/filingHexcodes";
import { observer } from "mobx-react";
import ToggleButton from "./ToggleButton"; // Import the separate ToggleButton component

const FinancialsTable = observer(({ cik, groupType, financialData }) => {
  const [isToggled, setIsToggled] = useState(false); // State to track toggle button
  const dropdownsRef = useRef(null);

  // Handle CIK changes
  useEffect(() => {
    if (financialsStore.cik !== cik) {
      financialsStore.setCik(cik);
    }

    const handleClickOutsideDropdown = (e) => {
      if (dropdownsRef.current && !dropdownsRef.current.contains(e.target)) {
        financialsStore.setCurrentlyOpenedFilter("");
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [cik]);

  const handleFilterChange = async ({ name, value }) => {
    await financialsStore.handleFilterChange(name, value);
  };

  const loadMore = async () => {
    await financialsStore.loadMore();
  };

  const years = Array.from(
    { length: 2025 - financialData?.start_year },
    (_, i) => financialData?.start_year + i
  );

  // Logic to toggle the currently opened filter
  const toggleCurrentlyOpenedFilter = (filterName) => {
    if (financialsStore.currentlyOpenedFilter === filterName) {
      financialsStore.setCurrentlyOpenedFilter(""); // Close the dropdown if it's already open
    } else {
      financialsStore.setCurrentlyOpenedFilter(filterName); // Open the dropdown
    }
  };

  // Handle toggle state (ON/OFF)
  const handleToggle = (isToggled) => {
    setIsToggled(isToggled); // Update the toggle state
  };

  return (
    <>
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <div className="flex items-center">
          <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
            {groupType.charAt(0).toUpperCase() + groupType.slice(1)}
          </h3>
          {/* <ToggleButton onToggle={handleToggle} />{" "} */}
          {/* ToggleButton added here */}
        </div>

        <div
          className="flex items-start md:gap-4 gap-1.5 flex-wrap"
          ref={dropdownsRef}
        >
          <FilterButton
            name="year"
            values={years.reverse()}
            value={financialsStore.filter.year}
            clearValue={async (e) =>
              handleFilterChange({ name: "year", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={financialsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={() => toggleCurrentlyOpenedFilter("year")}
          />
          <FilterButton
            name="formType"
            values={["10-Q", "10-K"]}
            value={financialsStore.filter.formType}
            clearValue={async (e) =>
              await handleFilterChange({ name: "formType", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={financialsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={() =>
              toggleCurrentlyOpenedFilter("formType")
            }
          />
        </div>
      </div>

      {financialsStore.loading ? (
        <div className="loading-page-container">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {/* Pass isToggled to the Table component */}
          <Table
            isToggled={isToggled} // Pass isToggled here
            data={financialsStore.filingsData.map((filingData) => {
              const url = `/${cik}/documents/${filingData.accessionNo}?filing=${filingData.form_type}`;
              return {
                ...filingData,
                url: url,
                period_of_report: `ended: ${filingData.period_of_report}`,
                pillCN: getHexCode(filingData.form_type) || "#ebf38c",
                title:
                  filingData.title.split(" ").length > 8
                    ? filingData.title.split(" ").slice(0, 8).join(" ") + "..."
                    : filingData.title.split(" ").slice(0, 8).join(" "),
              };
            })}
            references={[
              {
                reference: "form_type",
                pill: true,
              },
              { reference: "title" },
              {
                reference: "filingDate",
                additionalCN: "text-right",
              },
            ]}
          />

          {financialsStore.hasMoreData &&
            financialsStore.filingsData.length > 0 && (
              <button
                className="financials-load-more cursor-pointer hover:bg-lavender-smTint transition-all disabled:cursor-not-allowed"
                onClick={loadMore}
                disabled={financialsStore.paginating}
              >
                <span
                  className={`financials-load-more-btn ${
                    financialsStore.paginating ? "loading" : ""
                  }`}
                >
                  {financialsStore.paginating ? (
                    <LoadingSpinner size="w-4 h-4" className="border-2" />
                  ) : (
                    "+ Load More"
                  )}
                </span>
              </button>
            )}
        </>
      )}
    </>
  );
});

export default FinancialsTable;
