import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchInsiderTrading } from "../services/api";
import LoadingSpinner from "./LoadingSpinner";
import { FaCheck } from "react-icons/fa";
import "../styles/insider.css";
import InsiderTable from "./InsiderTable";
import ToggleButton from "./ToggleButton"; // Import ToggleButton
import _ from "lodash";

const transactionCodeMap = {
  A: "Grant or Award",
  C: "Conversion",
  D: "Disposition",
  E: "Expiration",
  F: "Tax Payment",
  G: "Bona Fide Gift",
  H: "Cancellation of Derivative",
  I: "Discretionary Transaction",
  J: "Other Acquisition",
  L: "Small Acquisition",
  M: "Derivative Exercise",
  O: "Out-of-money Derivative",
  P: "Open Market Purchase",
  S: "Open Market Sale",
  U: "Tender",
  W: "Will",
  X: "In-the-money Derivative",
  Z: "Voting Trust",
};

const Insider = () => {
  const { cik } = useParams();
  const navigate = useNavigate();
  const [insiderData, setInsiderData] = useState([]);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState("custom");
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterOptions, setFilterOptions] = useState({ S: true, P: true });
  const [tempFilterOptions, setTempFilterOptions] = useState(filterOptions);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeButton, setActiveButton] = useState("openMarketOnly");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isToggled, setIsToggled] = useState(false); // State for controlling the blur effect
  const scrollTimeoutRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);

  const saveState = (button) => {
    if (insiderData.length > 0) {
      const stateToSave = {
        insiderData,
        page,
        selected,
        filterOptions,
        hasMoreData,
        expandedRows,
        tempFilterOptions,
      };

      sessionStorage.setItem(
        `insiderAdjustments_${button}_${cik}`,
        JSON.stringify(stateToSave)
      );
      sessionStorage.setItem(
        `insiderScrollPosition_${button}_${cik}`,
        window.scrollY
      );
      sessionStorage.setItem(`activeButton_${cik}`, button);
    }
  };

  const loadState = (button) => {
    const savedState = JSON.parse(
      sessionStorage.getItem(`insiderAdjustments_${button}_${cik}`)
    );

    if (savedState) {
      setInsiderData(savedState.insiderData || []);
      setPage(savedState.page || 1);
      setSelected(savedState.selected || "custom");
      setFilterOptions(savedState.filterOptions || { P: true, S: true });
      setTempFilterOptions(
        savedState.tempFilterOptions || { P: true, S: true }
      );
      setHasMoreData(
        savedState.hasMoreData !== undefined ? savedState.hasMoreData : true
      );
      setExpandedRows(savedState.expandedRows || []);
    } else {
      setInsiderData([]);
      setPage(1);
      setHasMoreData(true);
      setExpandedRows([]);
    }
  };

  const getInsiderTradingData = useCallback(
    async (page, appliedFilters) => {
      if (!cik) return;

      setLoading(true);

      try {
        const data = await fetchInsiderTrading(
          cik,
          page,
          selected,
          appliedFilters
        );

        if (page === 1) {
          setInsiderData(Array.isArray(data) ? data : []);
        } else {
          setInsiderData((prev) => [
            ...prev,
            ...(Array.isArray(data) ? data : []),
          ]);
        }

        setNoData(data.length === 0);
        setHasMoreData(data.length > 0);
      } catch (error) {
        console.error("Fetching insider trading data failed", error);
      }

      setLoading(false);
      setLoadingMore(false);
    },
    [cik, selected]
  );

  const clearDataAndFetch = useCallback(
    async (newFilterOptions) => {
      setLoading(true);
      setExpandedRows([]);

      if (!_.isEqual(filterOptions, newFilterOptions)) {
        setInsiderData([]);
        setPage(1);

        setFilterOptions(newFilterOptions);
        setTempFilterOptions(newFilterOptions);

        await getInsiderTradingData(1, newFilterOptions);
      }

      setLoading(false);
    },
    [getInsiderTradingData, filterOptions]
  );

  useEffect(() => {
    let previousCik = sessionStorage.getItem("previousCik");

    const handleInitialLoad = async () => {
      if (previousCik && previousCik !== cik) {
        sessionStorage.removeItem(
          `insiderAdjustments_openMarketOnly_${previousCik}`
        );
        sessionStorage.removeItem(`insiderAdjustments_showAll_${previousCik}`);
        sessionStorage.removeItem(`activeButton_${previousCik}`);
      }

      sessionStorage.setItem("previousCik", cik);

      const storedActiveButton = sessionStorage.getItem(`activeButton_${cik}`);
      const savedState = JSON.parse(
        sessionStorage.getItem(
          `insiderAdjustments_${storedActiveButton || activeButton}_${cik}`
        )
      );

      if (storedActiveButton) {
        setActiveButton(storedActiveButton);
      }

      if (savedState) {
        loadState(storedActiveButton || activeButton);
      } else {
        const defaultFilters =
          storedActiveButton === "openMarketOnly" ||
          (!storedActiveButton && activeButton === "openMarketOnly")
            ? { P: true, S: true }
            : Object.keys(transactionCodeMap).reduce((acc, key) => {
                acc[key] = true;
                return acc;
              }, {});

        setFilterOptions(defaultFilters);
        setTempFilterOptions(defaultFilters);

        await getInsiderTradingData(1, defaultFilters);
      }
    };

    handleInitialLoad();
  }, [cik]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem(`insiderAdjustments_openMarketOnly_${cik}`);
      sessionStorage.removeItem(`insiderAdjustments_showAll_${cik}`);
      sessionStorage.removeItem(`activeButton_${cik}`);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [cik]);

  const loadMore = async () => {
    if (loadingMore || !hasMoreData) return;

    setLoadingMore(true);

    const nextPage = page + 1;
    const newData = await fetchInsiderTrading(
      cik,
      nextPage,
      selected,
      filterOptions
    );

    if (newData.length === 0) {
      setHasMoreData(false);
    } else {
      setInsiderData((prevData) => [...prevData, ...newData]);
      setPage(nextPage);
    }

    setLoadingMore(false);
  };

  const handleFilterChange = (name, value) => {
    setTempFilterOptions((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  const applyFilters = async () => {
    await clearDataAndFetch(tempFilterOptions);
  };

  const handleToggle = (toggleState) => {
    setIsToggled(toggleState); // Update the toggle state to control blur
  };

  const handleOpenMarketOnly = async () => {
    if (activeButton !== "openMarketOnly") {
      saveState("showAll");
      setActiveButton("openMarketOnly");

      const savedState = JSON.parse(
        sessionStorage.getItem(`insiderAdjustments_openMarketOnly_${cik}`)
      );

      if (savedState) {
        loadState("openMarketOnly");
      } else {
        const openMarketFilters = {
          P: true,
          S: true,
          ...Object.keys(transactionCodeMap).reduce((acc, key) => {
            if (key !== "P" && key !== "S") acc[key] = false;
            return acc;
          }, {}),
        };

        setFilterOptions(openMarketFilters);
        setTempFilterOptions(openMarketFilters);
        await getInsiderTradingData(1, openMarketFilters);
      }
    } else {
      loadState("openMarketOnly");
    }
  };

  const handleShowAll = async () => {
    if (activeButton !== "showAll") {
      saveState("openMarketOnly");
      setActiveButton("showAll");

      const savedState = JSON.parse(
        sessionStorage.getItem(`insiderAdjustments_showAll_${cik}`)
      );

      if (savedState) {
        loadState("showAll");
      } else {
        const allFilters = Object.keys(transactionCodeMap).reduce(
          (acc, key) => {
            acc[key] = true;
            return acc;
          },
          {}
        );

        setFilterOptions(allFilters);
        setTempFilterOptions(allFilters);
        await getInsiderTradingData(1, allFilters);
      }
    } else {
      loadState("showAll");
    }
  };

  const toggleFilterPanel = () => {
    setFilterVisible(!filterVisible);
  };

  const toggleRow = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((i) => i !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleRedirect = (accessionNo, formType) => {
    saveState(activeButton);
    navigate(`/${cik}/documents/${accessionNo}?filing=${formType}`);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = Object.keys(transactionCodeMap).filter((key) =>
    transactionCodeMap[key].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleScroll = () => {
    setScrolling(true);
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      setScrolling(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (insiderData.length > 0 || loading) {
        saveState(activeButton);
      }
    };
  }, [
    insiderData,
    page,
    selected,
    filterOptions,
    hasMoreData,
    expandedRows,
    activeButton,
    loading,
  ]);

  return (
    <div className="insider-container">
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
          Insider Trading
        </h3>
        {/* Add the toggle button */}
        {/* <ToggleButton onToggle={handleToggle} /> */}
        {noData && !loading ? null : (
          <div className="flex items-start md:gap-4 gap-1.5 flex-wrap">
            <div
              className={`${
                activeButton === "openMarketOnly"
                  ? "bg-transparent shadow-inner-purple-outline text-lavender-primary"
                  : "bg-lavender-primary text-white-primary"
              } rounded-2xl sm:px-[16px] sm:py-[10px] px-[12px] py-[10px] flex items-center justify-center gap-2 relative `}
            >
              <button
                className={`bg-transparent font-semibold tracking-wider whitespace-nowrap flex items-center gap-1 font-primary sm:text-sm text-[8px] ${
                  activeButton === "openMarketOnly" ? "text-black" : ""
                }`}
                onClick={handleOpenMarketOnly}
              >
                OPEN MARKET ONLY
              </button>
            </div>
            <div
              className={`${
                activeButton === "showAll"
                  ? "bg-transparent shadow-inner-purple-outline text-lavender-primary"
                  : "bg-lavender-primary text-white-primary"
              } rounded-2xl sm:px-[16px] sm:py-[10px] px-[12px] py-[10px] flex items-center justify-center gap-2 relative `}
            >
              <button
                className={`bg-transparent font-semibold tracking-wider whitespace-nowrap flex items-center gap-1 font-primary sm:text-sm text-[8px] ${
                  activeButton === "showAll" ? "text-black" : ""
                }`}
                onClick={handleShowAll}
              >
                SHOW ALL
              </button>
            </div>
            <div className="relative">
              <div
                className={`${
                  filterVisible
                    ? "bg-transparent shadow-inner-purple-outline text-lavender-primary"
                    : "bg-lavender-primary text-white-primary"
                } rounded-2xl sm:px-[16px] sm:py-[10px] px-[12px] py-[10px] flex items-center justify-center gap-2 relative `}
              >
                <button
                  className={`bg-transparent font-semibold tracking-wider whitespace-nowrap flex items-center gap-1 font-primary sm:text-sm text-[8px] ${
                    filterVisible ? "text-black" : ""
                  }`}
                  onClick={toggleFilterPanel}
                >
                  {filterVisible ? "HIDE FILTERS" : "SHOW FILTERS"}
                </button>
              </div>
              {filterVisible && (
                <div className="dropdown-panel shadow-subtle-soft">
                  <div className="filter-content">
                    <div className="flex items-center">
                      <input
                        type="text"
                        placeholder="Search"
                        className="filter-searchbar mt-1"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                      <FaCheck
                        className="cursor-pointer ml-2 mr-4 mt-1"
                        size={20}
                        onClick={applyFilters}
                      />
                    </div>
                    <span
                      className={`overflow-y-scroll custom-scrollbar ${
                        scrolling ? "scrolling" : ""
                      }`}
                      onScroll={handleScroll}
                    >
                      {filteredOptions.map((key) => (
                        <label key={key} className="filter-label">
                          <input
                            type="checkbox"
                            name={key}
                            checked={tempFilterOptions[key]}
                            onChange={(e) =>
                              handleFilterChange(key, e.target.checked)
                            }
                          />
                          {transactionCodeMap[key]}
                        </label>
                      ))}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="insider-table-content">
        <div className="insider-table-wrapper">
          {loading ? ( // Display spinner when loading data
            <div className="loading-page-container">
              <LoadingSpinner />
            </div>
          ) : noData && !loading ? (
            <div className="flex items-center justify-center mt-16 h-[68vh]">
              <h3 className="text-black-mdTint">
                The list of insider transactions for foreign entities, such as
                this one, is likely incomplete since these entities are not
                obligated to report insider transactions to the SEC.
              </h3>
            </div>
          ) : (
            <>
              <InsiderTable
                titles={[
                  "Name",
                  "Transaction Type",
                  "Price Per Share",
                  "Shares",
                  "Value",
                  "Date",
                  "10b5-1",
                ]}
                insiderData={insiderData}
                handleRedirect={handleRedirect}
                expandedRows={expandedRows}
                toggleRow={toggleRow}
                isToggled={isToggled} // Pass the toggle state to the table
              />
              {insiderData.length > 0 && hasMoreData && (
                <div
                  className="insider-load-more cursor-pointer hover:bg-lavender-smTint transition-all"
                  onClick={loadMore}
                >
                  <button className="insider-load-more-btn">
                    {loadingMore ? (
                      <LoadingSpinner size="w-4 h-4" className="border-2" />
                    ) : (
                      "+ Load More"
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Insider;
