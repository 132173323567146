import React from "react";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";
import navigationBarStore from "../stores/navigationBarStore";
import SearchBar from "./SearchBar";
import SavedHighlightsTable from "./SavedHighlightsTable";

const SavedHighlights = observer(() => {
  return (
    <div>
      <span className="flex items-center gap-4 mb-2">
        <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
        <div className="search-bar-container medium-large:flex block justify-end w-full">
          <SearchBar />
        </div>
      </span>
      <SavedHighlightsTable />
    </div>
  );
});

export default SavedHighlights;
