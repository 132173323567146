import React, { useEffect, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import LoadingSpinner from "./LoadingSpinner";
import { marked } from "marked";
import { GrPowerReset } from "react-icons/gr";
import WingmanLeft from "../images/Wingman_Left.png";
import WingmanRight from "../images/Wingman_Right.png";
import navigationBarStore from "../stores/navigationBarStore";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

const EarningsDetailContent = observer(
  ({
    earningsTranscript,
    earningsTranscriptSummary,
    refetchEarningsTranscript,
    refetchEarningsTranscriptSummary,
  }) => {
    const [documentsContainerHeight, setDocumentsContainerHeight] = useState(
      window.innerWidth < 896
        ? window.innerHeight - 160
        : window.innerHeight - 112
    );
    const [activeTab, setActiveTab] = useState("transcript");

    useEffect(() => {
      const handleResize = () => {
        setDocumentsContainerHeight(
          window.innerWidth < 896
            ? window.innerHeight - 160
            : window.innerHeight - 112
        );
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <>
        <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
        <section className="mt-2">
          <div className="flex items-end space-x-2 border-gray-300">
            <button
              className={`${
                activeTab === "transcript"
                  ? "bg-white-xsShade text-indigo-500 bg-white rounded-none"
                  : "text-gray-500 bg-gray-200"
              } px-4 py-2 rounded-t-lg overflow-hidden text-ellipsis whitespace-nowrap sm:text-base text-xs xxs:h-[40px] xs:h-[45px]`}
              onClick={() => setActiveTab("transcript")}
            >
              Transcript
            </button>
            <button
              className={`${
                activeTab === "summary"
                  ? "bg-white-xsShade text-indigo-500 bg-white rounded-none"
                  : "text-gray-500 bg-gray-200"
              } px-4 py-2 rounded-t-lg overflow-hidden text-ellipsis whitespace-nowrap sm:text-base text-xs xxs:h-[40px] xs:h-[45px]`}
              onClick={() => setActiveTab("summary")}
            >
              Transcript Summary
            </button>
            <button
              className={`${
                activeTab === "wingman"
                  ? "bg-white-xsShade text-indigo-500 bg-white rounded-none"
                  : "text-gray-500 bg-gray-200"
              } px-4 py-2 rounded-t-lg text-ellipsis whitespace-nowrap sm:text-base text-xs xxs:h-[40px] xs:h-[45px]`}
              onClick={() => setActiveTab("wingman")}
            >
              Wingman
            </button>
          </div>

          <div
            className="w-full shadow-subtle-soft rounded-2xl rounded-tl-none overflow-hidden"
            style={{ height: documentsContainerHeight }}
          >
            {activeTab === "transcript" && (
              <article className="relative w-full h-full">
                <div className="flex items-center justify-between px-4 py-2 bg-white-xsShade min-h-12">
                  <h3 className="font-primary font-semibold text-sm sm:text-lg text-start truncate">
                    {earningsTranscript?.topic
                      ? earningsTranscript.topic
                      : "Loading Transcript..."}
                  </h3>
                  <div className="flex items-center gap-4">
                    <GrPowerReset
                      className={`text-salmon cursor-pointer ${
                        !earningsTranscript && "hidden"
                      }`}
                      onClick={refetchEarningsTranscript}
                    />
                    {/* <span className="p-2 rounded-2xl hover:bg-lavender-primary hover:text-white-primary cursor-pointer">
                      <HiDotsVertical />
                    </span> */}
                  </div>
                </div>
                {earningsTranscript?.content?.length ? (
                  <div
                    className={`w-full h-document-full-height-offset text-start py-4 px-8 overflow-y-auto scroll-smooth`}
                  >
                    {earningsTranscript.content.map((transcript, index) => (
                      <article
                        key={index}
                        className="py-4 space-y-3"
                        id={index}
                      >
                        <header className="flex items-center justify-start gap-2 font-primary">
                          <div
                            className={`size-10 p-2 rounded-[14px] ${
                              transcript.speaker === "Operator"
                                ? "bg-peach"
                                : "bg-coral"
                            } flex items-center justify-center font-medium`}
                          >
                            {transcript.speaker
                              .split(" ")
                              .map((word) => word[0])
                              .slice(0, 2)
                              .join("")}
                          </div>
                          <h3 className="text-lg font-semibold">
                            {transcript.speaker}
                          </h3>
                        </header>
                        <pre className="bg-white-xsShade p-4 rounded-2xl">
                          {transcript.content}
                        </pre>
                      </article>
                    ))}
                  </div>
                ) : (
                  <div className="relative w-full h-document-full-height-offset">
                    <LoadingSpinner className="absolute top-1/2 left-1/2" />
                  </div>
                )}
              </article>
            )}

            {activeTab === "summary" && (
              <article className="relative w-full h-full">
                <div className="flex items-center justify-between px-4 py-2 bg-white-xsShade min-h-12">
                  <h3 className="font-primary font-semibold text-sm sm:text-lg text-start truncate">
                    {earningsTranscriptSummary?.data?.summary
                      ? "Transcript Summary"
                      : "Loading Transcript Summary..."}
                  </h3>
                  <div className="flex items-center gap-4">
                    <GrPowerReset
                      className={`text-salmon cursor-pointer ${
                        !earningsTranscriptSummary && "hidden"
                      }`}
                      onClick={refetchEarningsTranscriptSummary}
                    />
                    {/* <span className="p-2 rounded-2xl hover:bg-lavender-primary hover:text-white-primary cursor-pointer">
                      <HiDotsVertical />
                    </span> */}
                  </div>
                </div>
                {earningsTranscriptSummary?.data?.summary ? (
                  <div
                    className={`w-full h-document-full-height-offset text-start py-4 px-8 pl-12 overflow-y-auto scroll-smooth`}
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(
                        earningsTranscriptSummary.data.summary || ""
                      ),
                    }}
                  />
                ) : (
                  <div className="relative w-full h-document-full-height-offset">
                    <LoadingSpinner className="absolute top-1/2 left-1/2" />
                  </div>
                )}
              </article>
            )}

            {activeTab === "wingman" && (
              <article className="relative w-full h-full">
                <div className="flex items-center justify-between px-4 py-2 bg-white-xsShade min-h-12">
                  <h3 className="font-primary font-semibold sm:text-lg text-sm">
                    Wingman
                  </h3>
                </div>
                <div className="w-full h-document-full-height-offset font-primary transition-none duration-300 text-start py-4 px-8 overflow-y-scroll relative scroll-smooth flex flex-col">
                  <div className="max-w-3xl mx-auto text-center">
                    <p
                      className="text-xl font-bold mb-2 text-center"
                      style={{ fontSize: "1.25rem" }}
                    >
                      Introducing:
                    </p>
                    <p
                      className="text-3xl font-bold font-primary mb-2 text-center flex items-center justify-center"
                      style={{ fontWeight: "600" }}
                    >
                      <img
                        src={WingmanLeft}
                        alt="Wingman Left"
                        className="w-8 h-8 mr-2" // Adjust size as needed
                      />
                      Wingman
                      <img
                        src={WingmanRight}
                        alt="Wingman Right"
                        className="w-8 h-8 ml-2" // Adjust size as needed
                      />
                    </p>

                    <p
                      className="text-lg ml-2 mb-4 text-center"
                      style={{ fontSize: "1rem", maxWidth: "100%" }}
                    >
                      All of your questions about any filing, answered.
                    </p>
                  </div>

                  <div className="flex flex-col space-y-2">
                    {/* Right-side text box */}
                    <div className="flex justify-end">
                      <div
                        className="bg-lavender-smTint p-4 rounded-lg"
                        style={{
                          borderRadius: "3rem",
                          fontSize: "0.8rem",
                          lineHeight: "1.5rem",
                          maxWidth: "400px", // Adjust the max-width to control size
                          textAlign: "center", // Center align text inside
                        }}
                      >
                        What are Apple’s key revenue drivers?
                      </div>
                    </div>

                    {/* Main content below the right-side text box */}
                    <div className="flex">
                      <div className="max-w-3xl self-start flex-grow">
                        <div
                          className="bg-gray-100 p-4 rounded-lg shadow-lg text-left"
                          style={{
                            borderRadius: "3rem",
                            fontSize: "0.8rem",
                            lineHeight: "1.3rem", // Reduced line-height
                          }}
                        >
                          <p className="mb-2">
                            In Apple's Q3 2024 10-Q filing, the key revenue
                            drivers for the company are primarily its products
                            and services. The major segments contributing to
                            revenue include:
                          </p>
                          <ol className="list-decimal list-inside space-y-2">
                            <li>
                              <strong>iPhone:</strong> The iPhone is Apple's
                              largest revenue driver, consistently generating
                              the highest percentage of the company’s total net
                              sales. It is a crucial product in Apple's lineup.{" "}
                              <a href="#" className="text-indigo-600">
                                [1]
                              </a>
                            </li>
                            <li>
                              <strong>iPad:</strong> The iPad also contributes
                              significantly to Apple’s revenue, although it is
                              smaller compared to the iPhone. The iPad segment
                              includes all versions of the iPad tablet.{" "}
                              <a href="#" className="text-indigo-600">
                                [2]
                              </a>
                            </li>
                            <li>
                              <strong>Mac:</strong> This segment includes the
                              MacBook, MacBook Pro, iMac, Mac Pro, and other
                              desktop and portable computers. It is another
                              important revenue stream for Apple.{" "}
                              <a href="#" className="text-indigo-600">
                                [3]
                              </a>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    {/* Second text box below the main content */}
                    <div className="flex justify-end mt-8">
                      <div
                        className="bg-lavender-smTint p-4 rounded-lg"
                        style={{
                          borderRadius: "3rem",
                          fontSize: "0.8rem",
                          lineHeight: "1.3rem", // Reduced line-height
                          maxWidth: "400px", // Adjust the max-width to control size
                          textAlign: "center", // Center align text inside
                        }}
                      >
                        What did management say about key risks?
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 text-center">
                    <h2
                      className="text-xl font-primary font-bold mb-2"
                      style={{ fontSize: "1.1rem", fontWeight: "600" }}
                    >
                      Coming November 2024
                    </h2>
                    <button
                      className="bg-lavender-primary font-primary py-3 px-6 rounded-lg font-semibold"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "#ffffff", // Ensure white color
                      }}
                    >
                      Learn more
                    </button>
                  </div>
                </div>
              </article>
            )}
          </div>
        </section>
      </>
    );
  }
);
export default EarningsDetailContent;
