import React, { useState, useEffect, useRef } from "react";
import { fetchFiling } from "../services/api";
import LoadingSpinner from "./LoadingSpinner"; // Import the LoadingSpinner component
import * as pdfjsLib from "pdfjs-dist/webpack"; // Import the PDF.js library
import { MdCompareArrows } from "react-icons/md"; // Import the compare icon
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import navigationBarStore from "../stores/navigationBarStore";
import { observer } from "mobx-react";

let idCounter = 0;
const getContextText = (text, match) => {
  const index = text.indexOf(match);

  if (index === -1) return "";

  const findPreviousSecondSpace = (start) => {
    let spaceCount = 0;
    for (let i = start; i >= 0; i--) {
      if (text[i] === " ") {
        spaceCount++;
        if (spaceCount === 2) return i;
      }
    }
    return -1;
  };

  const findNextSecondSpace = (start) => {
    let spaceCount = 0;
    for (let i = start; i < text.length; i++) {
      if (text[i] === " ") {
        spaceCount++;
        if (spaceCount === 2) return i;
      }
    }
    return text.length;
  };

  const startContext = findPreviousSecondSpace(index - 1) + 1;
  const endContext = findNextSecondSpace(index + match.length);

  const contextBefore = text.substring(startContext, index).trim();
  const contextAfter = text.substring(index + match.length, endContext).trim();

  return `${contextBefore} <strong>${match}</strong> ${contextAfter}`;
};

const DocumentsContentReplica = observer(
  ({
    accessionNo,
    form_type,
    cik,
    searchTerm,
    setSearchResults,
    scrollToId,
    setLoadingInstances, // Pass this from SearchResultsSideBar to control loading spinner
  }) => {
    const [documentContent, setDocumentContent] = useState("");
    const [pdfText, setPdfText] = useState(""); // Store extracted PDF text
    const [loading, setLoading] = useState(false); // State to handle loading
    const [fetchError, setFetchError] = useState(false); // State to handle fetch error
    const [documentsContainerHeight, setDocumentsContainerHeight] = useState(
      window.innerHeight - 120
    ); // Initialize the height state
    const iframeRef = useRef(null);
    const navigate = useNavigate(); // Initialize navigate

    const fetchAndDisplayFiling = async () => {
      if (accessionNo && form_type) {
        try {
          setLoadingInstances(true); // Start loading spinner in SearchResultsSideBar
          setLoading(true); // Start loading spinner for the iframe
          setDocumentContent(""); // Clear previous document content
          setPdfText(""); // Clear previous PDF text
          setFetchError(false); // Reset fetch error state

          const filing = await fetchFiling(accessionNo, form_type);

          if (filing && filing.url) {
            const response = await fetch(filing.url);
            const contentType = response.headers.get("Content-Type");

            const iframe = iframeRef.current;

            // Handle PDF files
            if (contentType.includes("application/pdf")) {
              const pdfBlob = await response.blob();
              const pdfUrl = URL.createObjectURL(pdfBlob);

              // Extract text from the PDF using PDF.js
              const extractPdfText = async (pdfBlob) => {
                const pdf = await pdfjsLib.getDocument(
                  URL.createObjectURL(pdfBlob)
                ).promise;
                let extractedText = "";

                for (let i = 0; i < pdf.numPages; i++) {
                  const page = await pdf.getPage(i + 1);
                  const textContent = await page.getTextContent();
                  const pageText = textContent.items
                    .map((item) => item.str)
                    .join(" ");
                  extractedText += pageText + "\n";
                }

                setPdfText(extractedText); // Store extracted text
              };

              // Call the text extraction function
              await extractPdfText(pdfBlob);

              if (iframe) {
                iframe.src = pdfUrl; // Render the PDF in the iframe
              }
            }
            // Handle text/plain filings (like "LETTER") and enable search
            else if (
              form_type === "LETTER" ||
              contentType.includes("text/plain")
            ) {
              const textContent = await response.text();
              setDocumentContent(textContent); // Store the text for search functionality

              // Render the plain text into the iframe
              if (iframe) {
                const iframeDoc =
                  iframe.contentDocument || iframe.contentWindow.document;

                iframeDoc.open();
                iframeDoc.write(`
                <html>
                  <body style="margin: 0; padding: 20px; font-family: monospace; white-space: pre-wrap; background-color: white; color: black;">
                    ${textContent}
                  </body>
                </html>
              `);
                iframeDoc.close();
              }
            }
            // Handle normal HTML filings
            else {
              const text = await response.text();
              setDocumentContent(text); // Store content for search highlighting

              if (iframe) {
                const iframeDoc =
                  iframe.contentDocument || iframe.contentWindow.document;
                iframeDoc.open();
                iframeDoc.write(text);
                iframeDoc.close();
              }
            }
          } else {
            setFetchError(true); // Set error state if no filing data is returned
            setDocumentContent(""); // Clear content in case of error
            setLoadingInstances(false); // Stop loading spinner in SearchResultsSideBar
          }
        } catch (error) {
          console.error("Error fetching filing:", error);
          setFetchError(true); // Set error state if fetching fails
          setDocumentContent(""); // Clear content if an error occurs
          setLoadingInstances(false); // Stop loading spinner in SearchResultsSideBar
        } finally {
          setLoadingInstances(false); // Stop loading spinner in SearchResultsSideBar when done
          setLoading(false); // Stop loading spinner for the iframe
        }
      }
    };

    const highlightDocumentContent = () => {
      const term = searchTerm;
      let searchResults = [];

      // Handle search for extracted PDF text
      if (pdfText && term) {
        const regex = new RegExp(`\\b${term}\\b`, "gi");
        const matches = pdfText.match(regex);

        if (matches) {
          matches.forEach((match) => {
            idCounter += 1;
            const id = `highlight-${idCounter}`;
            searchResults.push({
              id,
              text: getContextText(pdfText, match),
            });
          });
          setSearchResults(searchResults);
        }
        return; // Stop the rest of the search logic since we're searching in PDF text
      }

      // Search logic for plain text/HTML content
      if (!documentContent || !term) {
        setLoadingInstances(false); // Ensure we stop the spinner even when no results are found
        return;
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(documentContent, "text/html");
      const body = doc.body;

      const highlightText = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const regex = new RegExp(`\\b${term}\\b`, "gi");
          const newText = node.textContent.replace(regex, (match) => {
            idCounter += 1;
            const id = `highlight-${idCounter}`;
            searchResults.push({
              id,
              text: getContextText(node.textContent, match),
            });
            setSearchResults(searchResults);
            return `<mark id="${id}" style="background-color: yellow;">${match}</mark>`;
          });
          const span = document.createElement("span");
          span.innerHTML = newText;
          return span;
        } else {
          node.childNodes.forEach((child) => {
            const highlightedChild = highlightText(child);
            if (highlightedChild) {
              node.replaceChild(highlightedChild, child);
            }
          });
          return node;
        }
      };

      if (term && documentContent) {
        highlightText(body);

        const iframe = iframeRef.current;
        if (iframe) {
          const iframeDoc =
            iframe.contentDocument || iframe.contentWindow.document;
          iframeDoc.body.innerHTML = "";
          iframeDoc.body.appendChild(body);
        }

        if (searchResults.length === 0) {
          setLoadingInstances(false); // Stop spinner if no results are found
        }
      }
    };

    useEffect(() => {
      fetchAndDisplayFiling();
    }, [accessionNo, form_type]);

    useEffect(() => {
      if (documentContent || pdfText) {
        highlightDocumentContent();
      }
    }, [documentContent, pdfText, searchTerm]);

    useEffect(() => {
      if (scrollToId) {
        const iframe = iframeRef.current;
        if (iframe) {
          const iframeDoc =
            iframe.contentDocument || iframe.contentWindow.document;
          const element = iframeDoc.getElementById(scrollToId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [scrollToId]);

    useEffect(() => {
      const handleResize = () => {
        setDocumentsContainerHeight(window.innerHeight - 120);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <div
        className={`p-6 overflow-hidden h-[100dvh] flex flex-col medium-large:ml-[320px]`}
      >
        <LogoWithMenuToggle
          navigationBarStore={navigationBarStore}
          className="mb-2"
        />
        <section className="flex-1 relative mt-2">
          <article
            className="w-full h-full rounded-2xl overflow-hidden group flex flex-col"
            style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
          >
            <div
              className="px-4 py-2 bg-white-xsShade relative flex-shrink-0 flex justify-between items-center"
              style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
            >
              <h3 className="font-primary font-semibold text-sm sm:text-lg text-left">
                Viewing Filing: {form_type}
              </h3>
              <button
                className={`bg-lavender-primary text-white-primary font-primary py-2 px-4 font-medium rounded-2xl hover:bg-lavender-primary/90 sm:text-sm text-[10px] flex items-center gap-1 ${
                  !accessionNo || !form_type || !cik
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={!accessionNo || !form_type || !cik}
                onClick={() =>
                  navigate(
                    `/${cik}/documents/${accessionNo}?filing=${form_type}`
                  )
                }
              >
                <MdCompareArrows />
                Go to Filing
              </button>
            </div>
            <div className="relative flex-1">
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <LoadingSpinner />
                </div>
              )}
              {fetchError && (
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <p className="text-center text-lg text-gray-500">
                    Filing could not be fetched. Please try again later.
                  </p>
                </div>
              )}
              {!fetchError && (
                <iframe
                  id="documentIframe"
                  ref={iframeRef}
                  className={`w-full h-full border-none transition-opacity duration-300 ${
                    loading ? "opacity-0" : "opacity-100"
                  }`}
                  title="Document Content"
                ></iframe>
              )}
            </div>
          </article>
        </section>
      </div>
    );
  }
);

export default DocumentsContentReplica;
