import React from "react";
import { useNavigate } from "react-router-dom";

// Function to obfuscate content (replace alphanumeric chars with #)
const obfuscateContent = (text) => {
  return text.replace(/[a-zA-Z0-9]/g, "#"); // Replace letters and numbers with #
};

export default function Table({ titles, data, references, isToggled }) {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full overflow-auto">
      {!data?.length ? (
        <div className="loading-page-container">
          <h3 className="text-black-mdTint">No data found</h3>
        </div>
      ) : (
        <table>
          <thead>
            {titles && (
              <tr>
                {titles.map((title, index) => (
                  <th key={index} className="px-4">
                    {title}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {data.map((tableRowData, i) => (
              <tr key={i} className="hover:bg-lavender-smTint">
                {references.map(({ reference, additionalCN, pill }, j) => (
                  <td
                    key={j}
                    className={`py-3 px-4 cursor-pointer ${
                      additionalCN ? additionalCN : ""
                    }`}
                    onClick={() => navigate(tableRowData["url"] || "#")}
                  >
                    <span
                      style={{
                        backgroundColor: pill ? tableRowData["pillCN"] : "",
                        filter: isToggled && j === 1 ? "blur(5px)" : "none", // Apply blur only to middle column (index 1)
                      }}
                      className={
                        pill
                          ? `${tableRowData["pillCN"]} table-pill text-center`
                          : ""
                      }
                    >
                      {/* Conditionally obfuscate content based on isToggled */}
                      {isToggled && j === 1
                        ? obfuscateContent(
                            tableRowData[reference] || "Undefined"
                          )
                        : tableRowData[reference] || "Undefined"}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
