import React, { useEffect, useRef } from "react";
import { FaRegBell } from "react-icons/fa";
import { GoBriefcase } from "react-icons/go";
import { IoDocumentTextOutline, IoFilter } from "react-icons/io5";
import { LuTable2 } from "react-icons/lu";

export default function PaywallPopup({ type, clearPopUp = () => {} }) {
  const popUpRef = useRef();

  useEffect(() => {
    const handleClickOutsideDropdown = (e) => {
      if (popUpRef.current && !popUpRef.current.contains(e.target)) {
        clearPopUp();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <div
        className="absolute top-1/2 left-1/2 bg-white-primary drop-shadow-subtle-soft -translate-x-1/2 -translate-y-1/2 rounded-2xl z-[10000] md:w-fit w-3/4 max-w-[1056px] max-h-[80vh] overflow-hidden"
        ref={popUpRef}
      >
        <div className="overflow-auto max-h-[80vh]">
          <h2 className="bg-lavender-smTint p-4 font-primary font-semibold text-lg">
            {type} — Only with Unlevered Plus
          </h2>
          <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-6 p-6">
            <aside className="text-start text-black-mdTint">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. <br />
              <br />
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur.{" "}
            </aside>
            <div className="bg-white-smShade h-[320px] w-full rounded-2xl"></div>
          </div>
          <h2 className="font-primary font-semibold text-lg px-6">
            Do more with Unlevered Plus:
          </h2>
          <div className="grid xl:grid-cols-3 grid-cols-1 p-6 gap-6">
            <article className="text-start">
              <heading className="font-semibold font-primary">
                Infinite Information
              </heading>
              <li className="list-none ml-0 flex items-center gap-2 text-black-mdTint">
                <span>
                  <GoBriefcase />
                </span>
                8000+ Companies
              </li>
              <li className="list-none ml-0 flex items-center gap-2 text-black-mdTint">
                <span>
                  <IoDocumentTextOutline />
                </span>
                Insider Trading + Transcripts
              </li>
            </article>
            <article className="text-start">
              <heading className="font-semibold font-primary">
                Get Information Faster
              </heading>
              <li className="list-none ml-0 flex items-center gap-2 text-black-mdTint">
                <span>
                  <IoFilter />
                </span>
                10 Screeners
              </li>
              <li className="list-none ml-0 flex items-center gap-2 text-black-mdTint">
                <span>
                  <FaRegBell />
                </span>
                10 Alerts
              </li>
            </article>
            <article className="text-start">
              <heading className="font-semibold font-primary">
                Supercharge Your Analytics
              </heading>
              <li className="list-none ml-0 flex items-center gap-2 text-black-mdTint">
                <span>
                  <LuTable2 />
                </span>
                Download Tables
              </li>
              <li className="list-none ml-0 flex items-center gap-2 text-black-mdTint">
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#949494"
                    stroke="#949494"
                    stroke-width="1"
                  >
                    <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"></path>
                  </svg>
                </span>
                Highlight and Share
              </li>
            </article>
          </div>
          <button className="bg-lavender-primary text-white-primary font-primary py-2 px-4 font-medium rounded-2xl hover:bg-lavender-primary/90 text-sm flex items-center gap-1 m-auto mb-6">
            Start free trial
          </button>
        </div>
      </div>
      <div className="absolute w-full h-full top-0 left-0 z-[2000] bg-black-primary opacity-50 transition-all" />
    </>
  );
}
